@import "~@sas-te/alfabeto-tokens/src/scss/mixins";
@import "~@sas-te/alfabeto-tokens/src/scss/functions";
@import "~@sas-te/alfabeto-tokens/src/scss/variables";
@import "~@/styles/variables";

































































































































































































































































































.aditional-content-input {
  margin-right: 10px;
  height: 75px;
}
